<template>
  <div class="container-fluid">
    <div class="row justify-content-center mt-4">
      <div class="col-12 bg-gray-1 py-5">
        <h3 class="rounded bg-primary text-center text-white mt-2">Master de Personal</h3>
        <div class="container">
          <div class="row justify-content-end mt-2 mb-4" v-if="locked === false">
            <!--BOTONERA  -->
            <b-button  variant="danger" pill @click="BlockAllStaffable()">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Cerrar">
                <b-icon icon="lock-fill"/>Cerrar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Cerrar">
                <b-icon icon="lock-fill"/>
              </div>
            </b-button>
            <b-button  variant="success" pill @click="OpenModal()">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Personal">
                <b-icon icon="person-plus-fill"/>Agregar Personal
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Personal">
                <b-icon icon="person-plus-fill"/>
              </div>
            </b-button>
            <!--BOTONERA  -->
          </div>
            <a-table :columns="StaffableTable.columns"
                     :data-source="StaffableTable.data"
                     @change="FetchStaffableTable"
                     bordered>
              <template slot="operationEmployees" slot-scope="record">
               <small> <b>{{record.operationEmployees.code}}</b></small> -
                {{record.operationEmployees.name}}
              </template>
              <template v-for="col in ['worked_hours','total_cost']" :slot="col" slot-scope="record">
                <div :key="col">
                  <!--NUMBER INPUT - -->
                  <a-input-number  v-if="record.editable" :value="record[col]"
                                  @change="handleChange($event, record.id, col)"
                                  :min="0" :step="1" size="large"/>
                  <!--Texto - -->
                  <span v-else>{{record[col]}}</span>
                </div>
              </template>
              <template slot="actions" slot-scope="text, record">
                <div class="editable-row-operations"
                     v-if="record.status === staffablesStatuses.abierto && locked === false">
                  <span v-if="record.editable && locked === false">
                    <b-button variant="primary" pill size="sm" @click="update(record.id)">
                      <b-icon icon="archive"/>Guardar
                    </b-button>
                    <b-button variant="warning" pill size="sm" @click="cancel(record.id)">
                      <b-icon icon="x"/>Cancelar
                    </b-button>
                  </span>
                  <span v-else>
                    <b-button :disabled="editingId !== ''"
                              variant="info" pill size="sm" @click="edit(record.id)">
                      <b-icon icon="pencil-square"/>Editar
                    </b-button>
                      <a-popconfirm title="¿Desea eliminar?" okType="danger"
                                    @confirm="remove(record.id)">
                        <a-icon slot="icon" type="delete" theme="twoTone" two-tone-color="#ff0000"/>
                        <button class="btn btn-danger btn-sm" style="border-radius: 50rem;">
                          <b-icon icon="trash"/>Eliminar
                        </button>
                      </a-popconfirm>
                  </span>
                </div>
                <span v-else class="bg-danger text-white p-1 rounded">
                  <b-icon icon="lock-fill"/>Bloqueado
                </span>
              </template>
            </a-table>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <b-modal title="Detalle de Personal" ref="personalCart" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--// Filtros-->
        <a-row class="my-2" align="middle" :gutter="[8,24]">
          <a-col :span="24">
            <!--TEXT - -->
            <a-input-search v-model="filterCode" @search="FetchOperationEmployeeTable()"
                            placeholder="Buscar por clave o nombre"
                            enter-button/>
            <!--TEXT - -->
          </a-col>
        </a-row>
        <!--// Filtros-->
        <div class="row justify-content-center mt-4">
          <!--TABLA-->
          <a-table bordered
                   :columns="operationEmployeeTable.columns"
                   :data-source="operationEmployeeTable.data"
                   :pagination="operationEmployeeTable.pagination"
                   :loading="operationEmployeeTable.loading"
                   @change="FetchOperationEmployeeTable"
                   :row-key="record => record.id">
            <template slot="details" slot-scope="data">
              <a-tabs default-active-key="1" >
                <a-tab-pane key="1" tab="General">
                  <a-descriptions :title="`${data.name} - ${data.code}`" size="small" :column="{ md: 1, xl: 3}" >
                    <a-descriptions-item>
                      <strong class="text-primary" v-if="data.status">ACTIVO</strong>
                      <strong class="text-danger" v-else>INACTIVO</strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Costo x hora">
                      <strong> {{new Intl.NumberFormat("en-US", {style: "currency", currency: "MXN"}).format(data.cost_hour)  }}</strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Planta">
                      <strong class="text-real-blue">{{ data.workCenters.name }}</strong>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Detalles">
                  <a-descriptions :title="`${data.name} - ${data.code}`" size="small" :column="{ md: 2, xl: 3}" >
                    <a-descriptions-item label="Planta">
                      <strong class="text-real-blue">{{ data.workCenters.name }}</strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Turno" v-if="data.operationShifts">
                      <strong class="text-real-blue">
                        {{ data.operationShifts.name }}
                        ({{ moment(data.operationShifts.initial_time).format('HH:mm') }} -
                        {{ moment(data.operationShifts.final_time).format('HH:mm') }} )
                      </strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Puesto">
                      <strong> {{ data.operationPositions.name }}</strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Área">
                      <strong> {{ data.operationAreas.name }}</strong>
                    </a-descriptions-item>
                    <a-descriptions-item label="Compañia contratista" v-if="data.operationCompanies">
                      <strong> {{ data.operationCompanies.name }}</strong>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-tab-pane>
              </a-tabs>
            </template>
            <template slot="action" slot-scope="data">
              <b-button  variant="primary"  pill @click="Select(data)" size="sm">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Seleccionar">
                  <b-icon icon="hand-index-thumb"/>
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Seleccionar">
                  <b-icon icon="hand-index-thumb"/>
                </div>
              </b-button>
            </template>
          </a-table>
          <!--TABLA-->
        </div>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
import { staffablesStatuses } from '@/constant/staffables'
export default {
  name: 'personalTab',
  mixins: [fractalMixin],
  props: {
    workCenter: {
      type: [String, undefined],
      required: false,
    },
    staffableId: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    staffableType: {
      type: [String],
      required: false,
      default: 'formulations',
      validator: (value) => ['formulations', 'crushings'].indexOf(value) !== -1,
    },
    locked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      // Constantes
      // constantes
      staffablesStatuses: staffablesStatuses,
      // Tabla
      filterCode: '',
      operationEmployeeTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            width: '10%',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // editable row table
      StaffableTable: {
        data: [],
        columns: [
          {
            title: 'Empleado',
            dataIndex: '',
            width: '40%',
            scopedSlots: { customRender: 'operationEmployees' },
          },
          {
            title: 'Horas Trabajadas',
            dataIndex: '',
            width: '15%',
            scopedSlots: { customRender: 'worked_hours' },
          },
          {
            title: 'Costo Total',
            dataIndex: '',
            width: '15%',
            scopedSlots: { customRender: 'total_cost' },
          },
          {
            title: 'Acciones',
            dataIndex: 'actions',
            width: '20%',
            scopedSlots: { customRender: 'actions' },
          },
        ],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
      },
      editingId: '',
      cacheData: [],
    }
  },
  methods: {
    async FetchOperationEmployeeTable(pagEvent = {}) {
      const params = {
        include: 'workCenters,operationPositions,operationAreas,operationCompanies,operationShifts',
        'fields[workCenters]': 'name',
        'fields[operationPositions]': 'name',
        'fields[operationAreas]': 'name',
        'fields[operationCompanies]': 'name',
        'fields[operationShifts]': 'name,initial_time,final_time',
        sort: 'name',
        'filter[status]': 1,
        'filter[workCenters]': this.workCenter,
      }
      if (this.staffableType === 'formulations') {
        params['filter[notInFormulations]'] = this.staffableId
      } else if (this.staffableType === 'crushings') {
        params['filter[notInCrushings]'] = this.staffableId
      }
      if (this.filterCode !== '') params['filter[codeName]'] = this.filterCode
      await this.GetTableResource(pagEvent, 'operationEmployees', this.operationEmployeeTable, params)
    },
    async Select(employee) {
      // send formulation - employee
      await this.AxiosPost(`/save-staffables/${employee.id}`, {
        type: this.staffableType,
        staffable_id: this.staffableId,
      })
      await this.FetchOperationEmployeeTable()
      await this.FetchStaffableTable()
    },
    async OpenModal() {
      this.$refs.personalCart.show()
      await this.FetchOperationEmployeeTable()
    },
    CloseModal() {
      this.$refs.personalCart.hide()
    },
    async FetchStaffableTable(pagEvent) {
      const params = {
        include: 'operationEmployees',
        'fields[operationEmployees]': 'code,name',
      }
      if (this.staffableType === 'formulations') {
        params['filter[formulations]'] = this.staffableId
      } else if (this.staffableType === 'crushings') {
        params['filter[crushings]'] = this.staffableId
      }

      await this.GetTableResource(pagEvent, 'staffables', this.StaffableTable, params)
      this.cacheData = [...this.StaffableTable.data]
    },
    // Editable row table
    handleChange(value, id, column) {
      const newData = [...this.StaffableTable.data]
      const target = newData.filter(item => id === item.id)[0]
      if (target) {
        target[column] = value
        this.StaffableTable.data = newData
      }
    },
    edit(id) {
      const newData = [...this.StaffableTable.data]
      const target = newData.filter(item => id === item.id)[0]
      this.editingId = id
      if (target) {
        target.editable = true
        this.StaffableTable.data = newData
      }
    },
    async update(id) {
      const newData = [...this.StaffableTable.data]
      const newCacheData = [...this.cacheData]
      const target = newData.filter(item => id === item.id)[0]
      const targetCache = newCacheData.filter(item => id === item.id)[0]
      if (target && targetCache) {
        delete target.editable
        this.StaffableTable.data = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
        // axios put with fractal
        this.StaffableTable.loading = true
        await this.PutResource({
          resourceType: 'staffables',
          form: {
            id: target.id,
            worked_hours: target.worked_hours,
            total_cost: target.total_cost,
          },
        }, async () => await this.updatedCallback(), async () => await this.updatedCallback())
      }
      this.editingId = ''
    },
    cancel(id) {
      const newData = [...this.StaffableTable.data]
      const target = newData.filter(item => id === item.id)[0]
      this.editingId = ''
      if (target) {
        Object.assign(target, this.cacheData.filter(item => id === item.id)[0])
        delete target.editable
        this.StaffableTable.data = newData
      }
    },
    async remove(id) {
      this.StaffableTable.loading = true
      await this.DeleteResource('staffables', id, async () => {
        await this.FetchStaffableTable()
      }, async () => await this.updatedCallback())
    },
    async updatedCallback() {
      await this.FetchStaffableTable()
      this.StaffableTable.loading = false
    },
    async BlockAllStaffable() {
      const me = this
      Modal.confirm({
        title: '¿Desea bloquear los registros de personal asignados al recurso?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          await me.AxiosPost('/block-staffable', {
            staffableId: me.staffableId,
            staffableType: me.staffableType,
          }, async() => {
            await me.FetchStaffableTable()
          })
        },
        onCancel () {
        },
      })
    },
  },
  async mounted() {
    await this.FetchStaffableTable()
    if (this.StaffableTable.data.length > 0) {
      this.cacheData = this.StaffableTable.data.map(item => ({ ...item }))
    }
  },
}
</script>

<style scoped>

</style>
