<template>
  <div class="container-fluid">
    <div class="row justify-content-center mt-4">
      <div class="col-sm-12 bg-gray-1 py-5">
        <h3 class="rounded bg-primary text-center text-white mt-1">Lote Terminado</h3>
        <div class="container">
          <div class="row justify-content-center mt-1 mb-2" v-if="locked === false">
            <!--BOTONERA  -->
            <div v-if="!batchCode" class="mt-4">
              <template v-if="canBeAttached">
                <b-button  variant="success" class="mr-5" pill @click="NewOpenModal()">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Crear">
                    <b-icon icon="bookmark-plus"/>Crear Lote
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Crear">
                    <b-icon icon="bookmark-plus"/>
                  </div>
                </b-button>
                <b-button variant="info" class="ml-5" pill @click="OpenModal()">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar">
                    <b-icon icon="bookmark-check"/>Agregar a Lote
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Agregar">
                    <b-icon icon="bookmark-check"/>
                  </div>
                </b-button>
              </template>
              <template v-else>El recurso no puede ser asignado a un lote, ya que petenece a una trituración.</template>
            </div>
            <template v-if="batchCode">
              <h5 class="text-center mt-4">
                Asignado a: <strong>{{batchCode}}</strong>
              </h5>
            </template>
            <!--BOTONERA  -->
          </div>
          <div v-else>
            <h6 class="text-secondary font-weight-bold text-center mt-5">
              El Estatus del recurso no permite agregarlo a un Lote
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL AGREGAR -->
    <b-modal title="Agregar a Lote" ref="batchModal" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--// Filtros-->
        <a-row class="my-2" align="middle" :gutter="[8,24]">
          <a-col :span="24">
            <!--TEXT - -->
            <a-input-search v-model="filterCode" @search="FetchBatchesTable()"
                            placeholder="Buscar por Lote o Folio o Número de material SAP"
                            enter-button/>
            <!--TEXT - -->
          </a-col>
        </a-row>
        <!--// Filtros-->
        <!--TABLA-->
        <a-table bordered
                 :columns="batchesTable.columns"
                 :data-source="batchesTable.data"
                 :pagination="batchesTable.pagination"
                 :loading="batchesTable.loading"
                 @change="FetchBatchesTable"
                 :row-key="record => record.id">
          <template slot="details" slot-scope="row">
            <a-descriptions bordered size="small" :column="{ sm: 2}">
              <a-descriptions-item label="Folio">{{row.code}}</a-descriptions-item>
              <a-descriptions-item label="Estatus">
                <small class="bg-primary px-2 pt-1 pb-2 rounded text-white "
                        :class="{ 'bg-primary': row.warehouse_status === batchStatuses.almacenado, 'bg-warning': row.warehouse_status === batchStatuses.noAlmacenado }">
                  {{row.warehouse_status}}
                </small>
              </a-descriptions-item>
              <a-descriptions-item label="Número de material SAP">{{row.formulation_type_code}}</a-descriptions-item>
              <a-descriptions-item label="Lote">{{row.lote}}</a-descriptions-item>
              <a-descriptions-item label="Almacén">{{row.almacen}}</a-descriptions-item>
              <a-descriptions-item label="Cantidad">{{row.quantity}}</a-descriptions-item>
            </a-descriptions>
          </template>
          <template slot="action" slot-scope="data">
            <b-button  variant="primary"  pill @click="Select(data)" size="sm">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Seleccionar">
                <b-icon icon="hand-index-thumb"/>
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Seleccionar">
                <b-icon icon="hand-index-thumb"/>
              </div>
            </b-button>
          </template>
        </a-table>
        <!--TABLA-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL AGREGAR -->
    <!-- MODAL -->
    <b-modal title="Crear Nuevo Lote" ref="newBatchModal" size="lg" @hide="NewCloseModal" >
      <b-container fluid>
        <!--// Formulario-->
        <a-form-model :model="batchesForm.form"
                      v-show="!spinnerLoad"
                      :rules="batchesRules"
                      ref="productionForm" layout="vertical">

          <a-row align="middle" :gutter="[8,24]">
            <!--TEXT - Lote-->
            <a-col :sm="24">
              <a-form-model-item label="Lote" ref="lote"  prop="lote" >
                <a-input v-model="batchesForm.lote"
                         placeholder="Lote" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--SELECT -formulationTypes  -->
            <a-col :sm="24">
              <a-form-model-item label="Número de material SAP" ref="formulation_type_code" prop="formulation_type_code" >
                <ResourceSelect v-model="batchesForm.formulation_type_code"
                                OptionValue="name"
                                urlResource="/formulationTypes"
                                placeholder="Número de material SAP">
                  <span slot-scope="{option}">{{ option.name }} - {{option.details}}</span>
                </ResourceSelect>
              </a-form-model-item>
            </a-col>
            <!--TEXT - Almacén-->
            <a-col :sm="12">
              <a-form-model-item label="Almacén" ref="almacen"  prop="almacen" >
                <a-input v-model="batchesForm.almacen" disabled
                         placeholder="Almacén" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT - Cantidad-->
            <a-col :sm="12">
              <a-form-model-item label="Cantidad" ref="quantity"  prop="quantity" >
                <a-input v-model="batchesForm.quantity" disabled
                         placeholder="Cantidad" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row class="mt-3" align="middle" type="flex" justify="end">
            <a-col class="mr-4">
              <b-button @click="Submit"
                        variant="primary" pill block>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
            </a-col>
          </a-row>
        </a-form-model>
        <my-spinner :load="spinnerLoad" />
        <!--// Formulario-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="NewCloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal, notification } from 'ant-design-vue'
import { batchStatuses } from '@/constant/Batches'
export default {
  name: 'batchFinishedTab',
  mixins: [fractalMixin],
  props: {
    batchable_id: {
      required: false,
      type: [String, Number],
      default: 0,
    },
    batchable_type: {
      required: false,
      type: String,
      default: 'formulations',
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['formulations', 'crushings'].indexOf(value) !== -1
      },
    },
    locked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      // Constantes
      batchStatuses,
      // Tabla
      filterCode: '',
      batchesTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            width: '10%',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      batchesForm: {
        formulation_type_code: undefined,
        lote: '',
        almacen: 'PT01',
        quantity: '',
        batchable_id: 0,
        batchable_type: '',
      },
      spinnerLoad: false,
      batchesRules: {
        formulation_type_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        lote: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
      },
      // cuando ya pertenece a un batch
      batchCode: null,
      canBeAttached: false,
    }
  },
  methods: {
    // <!-- Verify and Validate batchable -->
    async VerifyIfBatcheableIsInABatch() {
      await this.AxiosGet('/verify-batchable', {
        batchable_id: this.batchable_id,
        batchable_type: this.batchable_type,
      }, ({ data }) => {
        this.canBeAttached = data.canBeAttached
        this.batchCode = data.batch?.code
      })
    },
    // <!-- Verify and Validate batchable -->
    // <!-- MODAL AGREGAR -->
    async OpenModal() {
      this.$refs.batchModal.show()
      await this.FetchBatchesTable()
    },
    CloseModal() {
      this.$refs.batchModal.hide()
    },
    async FetchBatchesTable(pagEvent = {}) {
      const params = {
        sort: '-code',
        'filter[warehouseStatus]': batchStatuses.noAlmacenado,
      }
      if (this.filterCode !== '') params['filter[code]'] = this.filterCode
      await this.GetTableResource(pagEvent, 'batches', this.batchesTable, params)
    },
    async Select(batch) {
      // send batchable_id & batchable_type
      const that = this
      Modal.confirm({
        title: '¿Desea agregar este recurso al Lote seleccionado?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          that.batchesTable.loading = true
          await that.AxiosPost('/attach-batchable', {
            code: batch.code,
            batchable_id: that.batchable_id,
            batchable_type: that.batchable_type,
          }, async ({ data }) => {
            await that.OnSuccess(data, 1)
            that.CloseModal()
          }, () => {
            that.batchesTable.loading = false
          })
        },
        onCancel () {
          notification.info({
            message: 'No se ha agregado al Lote.',
          })
        },
      })
    },
    // <!-- MODAL AGREGAR -->
    // <!-- MODAL NUEVO -->
    async NewOpenModal() {
      this.$refs.newBatchModal.show()
      this.ResetNewModal()
    },
    NewCloseModal() {
      this.$refs.newBatchModal.hide()
      this.ResetNewModal()
    },
    ResetNewModal() {
      this.batchesForm.formulation_type_code = undefined
      this.batchesForm.lote = ''
      this.batchesForm.almacen = 'PT01'
      this.batchesForm.quantity = ''
      this.batchesForm.batchable_type = this.batchable_type
      this.batchesForm.batchable_id = this.batchable_id
    },
    async Submit() {
      this.spinnerLoad = true
      await this.AxiosPost('/create-batch', this.batchesForm, async ({ data }) => {
        await this.OnSuccess(data, 0)
      }, () => {
        this.spinnerLoad = false
      })
    },
    async OnSuccess(data, theCase = 0) {
      if (theCase) {
        // Attach
        this.batchesTable.loading = false
        this.CloseModal()
      } else {
        // New
        this.spinnerLoad = false
        this.NewCloseModal()
      }
      // Ambos
      this.batchCode = data.batch.code
      this.canBeAttached = false
      notification.success({
        message: 'Recurso agregado a Lote:',
        description: `${data.batch.code}`,
      })
    },
    // <!-- MODAL NUEVO -->
  },
  async mounted() {
    await this.VerifyIfBatcheableIsInABatch()
  },
}
</script>

<style scoped>

</style>
