<template>
<div>
  <template v-if="(batchable_type === 'formulations' && status === formulationStatuses.abierto) ||
(batchable_type === 'crushings' && status === crushingStatuses.abierto)">
    <b-button  variant="warning" class="mr-5" pill @click="OpenModal()">
      <div class="d-none d-md-block" v-b-tooltip.hover title="Cerrar">
        <b-icon icon="lock"/>Cerrar
      </div>
      <div class="d-md-none" v-b-tooltip.hover title="Cerrar">
        <b-icon icon="lock"/>
      </div>
    </b-button>
  </template>
  <!-- MODAL AGREGAR -->
  <b-modal title="Detalle de Cierre" ref="quantitiesModal" size="lg" @hide="CloseModal" >
    <b-container fluid>
      <!--// Formulario-->
      <a-form-model :model="form"
                    v-show="!spinnerLoad"
                    :rules="rules"
                    ref="quantitiesForm" layout="vertical">

        <a-row align="middle" :gutter="[8,24]">
          <!--TEXT - Cantidad Total de Lote Terminado-->
          <a-col :sm="12">
            <a-form-model-item label="Cantidad Total de Lote Terminado" ref="total_quantity"  prop="total_quantity" >
              <a-input-number v-model="form.total_quantity" :min="0" :step="1" size="large" style="width: 80%"/>
              <strong class="ml-1">TON</strong>
            </a-form-model-item>
          </a-col>
          <a-col :sm="12">
            <a-form-model-item label="Contenedores Totales" ref="total_containers"  prop="total_containers" >
              <a-input-number v-model="form.total_containers" :min="0" :step="1" size="large" style="width: 80%"/>
              <strong class="ml-1">PZS</strong>
            </a-form-model-item>
          </a-col>
          <template v-if="batchable_type === 'formulations'">
            <h6 class="text-warning font-weight-bold text-center">En caso de ser Formulación de tipo PTAR</h6>
            <hr class="bg-secondary">
            <a-col :sm="24" :md="8">
              <a-form-model-item label="Costo Total de insumos" ref="supplies_cost"  prop="supplies_cost" >
                <strong class="mr-1">$</strong>
                <a-input-number v-model="form.supplies_cost" :min="0" :step="1" size="large" style="width: 80%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-model-item label="costo T/Agua" ref="water_cost"  prop="water_cost" >
                <strong class="mr-1">$</strong>
                <a-input-number v-model="form.water_cost" :min="0" :step="1" size="large" style="width: 80%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-model-item label="Cantidad de Lodo Residual" ref="mud_quantity"  prop="mud_quantity" >
                <a-input-number v-model="form.mud_quantity" :min="0" :step="1" size="large" style="width: 80%"/>
                <strong class="ml-1">TON</strong>
              </a-form-model-item>
            </a-col>
          </template>
        </a-row>
        <a-row class="mt-3" align="middle" type="flex" justify="end">
          <a-col class="mr-4">
            <b-button @click="Submit"
                      variant="primary" pill block>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
          </a-col>
        </a-row>
      </a-form-model>
      <my-spinner :load="spinnerLoad" />
      <!--// Formulario-->
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- MODAL AGREGAR -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { formulationStatuses } from '@/constant/formulations'
import { crushingStatuses } from '@/constant/crushings'
export default {
  name: 'AddQuantityDetailAndCloseBatchable',
  mixins: [fractalMixin],
  props: {
    batchable_id: {
      required: false,
      type: [String, Number],
      default: 0,
    },
    batchable_type: {
      required: false,
      type: String,
      default: 'formulations',
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['formulations', 'crushings'].indexOf(value) !== -1
      },
    },
    status: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      // constats
      formulationStatuses,
      crushingStatuses,
      // constats
      spinnerLoad: false,
      form: {
        batchable_id: 0,
        batchable_type: '',
        total_quantity: 0,
        total_containers: 0,
        supplies_cost: 0,
        water_cost: 0,
        mud_quantity: 0,
      },
      rules: {
        total_quantity: [
          { required: true, message: 'Campo obligatorio.', trigger: 'blur' },
          // { min: 0, message: 'Debe ser igual o mayor a cero.', trigger: 'change' },
        ],
        total_containers: [
          { required: true, message: 'Campo obligatorio.', trigger: 'blur' },
          // { min: 0, message: 'Debe ser igual o mayor a cero.', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    SetForm() {
      this.form.batchable_id = this.batchable_id
      this.form.batchable_type = this.batchable_type
    },
    OpenModal () {
      this.$refs.quantitiesModal.show()
    },
    CloseModal () {
      this.$refs.quantitiesModal.hide()
      this.SetForm()
    },
    Submit() {
      this.$refs.quantitiesForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          // Post
          await this.AxiosPost('/close-batchable', this.form, async ({ data }) => {
            console.log(data)
            await this.OnSuccess()
          }, () => {
            this.spinnerLoad = false
          })
        } else return false
      })
    },
    OnSuccess() {
      this.spinnerLoad = false
      this.CloseModal()
      this.$emit('Updated')
    },
  },
  mounted() {
    this.SetForm()
  },
}
</script>

<style scoped>

</style>
